import { useQuery, gql } from '@apollo/client';

const MLPTotalLiquidity = gql`
  query MLPTotalLiquidity {
    stakePositions(where: { id_startsWith: "2104-2-40" }) {
      liquidityStakedBalance
      id
    }
  }
`;

export function useMLPTotalLiquidity() {
  const { data } = useQuery(MLPTotalLiquidity, {
    pollInterval: 5000
  });
  if (data && data.stakePositions) {
    const total = data.stakePositions.reduce(
      // @ts-ignore
      (accumulator, v) => accumulator + BigInt(v.liquidityStakedBalance),
      BigInt(0)
    );
    return total;
  }
}
