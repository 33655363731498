import { useEffect, useState } from 'react';
import axios from 'axios';
import numeral from 'numeral';
import { useUserFarmingData } from 'contexts/UserFarmingDataContext';
import AssetType from 'classes/AssetType';
import { useGlobalLotteryData } from 'contexts/GlobalLotteryDataContext';
import { useModal } from 'hooks';
import JumboPoolModal from 'components/Modal/JumboPoolModal';
import ClaimBonusRewardsModal from 'components/Modal/ClaimBonusRewardsModal';
import MLPPoolModal from 'components/Modal/MLPPoolModal';
import {
  POOLS_IDS,
  POOLS_API,
  ADD_POOL_URL
} from 'constants/FarmingPoolConstants';
import { useWallet } from 'contexts/WalletContext';
import { useMLPTotalLiquidity } from 'contexts/useMLPTotalLiquidity';
import Balance from 'classes/Balance';

const displayAmount = (amount: Balance | null) => {
  if (amount === null) {
    return '--';
  }
  if (+amount.toStringUnrounded() < 1 && !amount.isZero()) {
    return '< 1';
  }
  return numeral(amount?.toStringUnrounded()).format('0,0.00');
};

const Staking = () => {
  const { selectedAccount } = useWallet();
  const { currentBlockNumber } = useGlobalLotteryData();
  const [isStake, setIsStake] = useState(true);
  const [poolId, setPoolId] = useState(POOLS_IDS.JUMBO);
  const { ModalWrapper, showModal, hideModal } = useModal();
  const {
    ModalWrapper: MLPPoolModalWrapper,
    showModal: showMLPPoolModal,
    hideModal: hideMLPPoolModal
  } = useModal();
  const {
    ModalWrapper: ClaimModalWrapper,
    showModal: showClaimModal,
    hideModal: hideClaimModal
  } = useModal();

  const [jumboPoolData, setJumboPoolData] = useState({});
  const [MLPPoolData, setMLPPoolData] = useState({});
  const totalStakingMLP = useMLPTotalLiquidity();

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(POOLS_API.JUMBO);
      setJumboPoolData(res.data.pool);

      const res2 = await axios.get(POOLS_API.MANTA_JUMBO_LP);
      setMLPPoolData(res2.data.pool);
    }
    fetchData().catch(console.error);
  }, [currentBlockNumber]);

  const {
    userStakedBalanceInJumboPool,
    userUnclaimedBalanceInJumboPool,
    userStakedBalanceInMLPPool,
    userUnclaimedBalanceInMLPPool
  } = useUserFarmingData();

  const _showStakeModal = () => {
    setIsStake(true);
    showModal();
  };

  const _showUnStakeModal = () => {
    setIsStake(false);
    showModal();
  };

  const showJumboPoolClaimModal = () => {
    setPoolId(POOLS_IDS.JUMBO);
    showClaimModal();
  };

  const showLPPoolClaimModal = () => {
    setPoolId(POOLS_IDS.MANTA_JUMBO_LP);
    showClaimModal();
  };

  const _showMLPPoolStakedModal = () => {
    setIsStake(true);
    showMLPPoolModal();
  };

  const _showMLPPoolUnstakedModal = () => {
    setIsStake(false);
    showMLPPoolModal();
  };

  return (
    <div className="flex items-center justify-between">
      <div className="w-[590px] bg-primary h-[320px] rounded-3xl px-12 py-6">
        <div className="flex items-center justify-between">
          <div className="font-title text-2xl leading-10">SHP/MANTA Pool</div>
          <a
            href={ADD_POOL_URL}
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-between"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20.5C15.5229 20.5 20 16.0229 20 10.5C20 4.97715 15.5229 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0229 4.47715 20.5 10 20.5ZM11.25 6.75C11.25 6.05965 10.6904 5.5 10 5.5C9.30965 5.5 8.75 6.05965 8.75 6.75V9.25H6.25C5.55965 9.25 5 9.80964 5 10.5C5 11.1904 5.55965 11.75 6.25 11.75H8.75V14.25C8.75 14.9404 9.30965 15.5 10 15.5C10.6904 15.5 11.25 14.9404 11.25 14.25V11.75H13.75C14.4404 11.75 15 11.1904 15 10.5C15 9.80965 14.4404 9.25 13.75 9.25H11.25V6.75Z"
                fill="#FC6A00"
              />
            </svg>
            <span className="text-secondary font-title ml-2">Add</span>
          </a>
        </div>

        <div className="my-6">
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">Maturity</span>
            <span>30 Nov 2023 (90 days)</span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">Total Staked</span>
            <span>
              {totalStakingMLP
                ? numeral(totalStakingMLP)
                    .divide(10 ** AssetType.MLP().numberOfDecimals)
                    .format('0,0.00')
                : '--'}{' '}
              MLP
            </span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">APR</span>
            <span>{numeral((MLPPoolData as any).apr).format('0.00%')}</span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">My Staked</span>
            <span>
              {userStakedBalanceInMLPPool === null
                ? '--'
                : numeral(userStakedBalanceInMLPPool?.toString(2)).format(
                    '0,0.00'
                  )}{' '}
              MLP
            </span>
          </div>
          <div className="flex items-center justify-between leading-5">
            <span className="text-[rgba(43,48,53,0.8)]">
              My Unclaimed Rewards
            </span>
            <span>{displayAmount(userUnclaimedBalanceInMLPPool)} SHP</span>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={_showMLPPoolStakedModal}
            disabled={!selectedAccount}
            className="btn-tertiary rounded-xl w-[110px] h-[59px] font-title"
          >
            Stake
          </button>
          <button
            onClick={_showMLPPoolUnstakedModal}
            disabled={!selectedAccount || userStakedBalanceInMLPPool?.isZero()}
            className="btn-tertiary rounded-xl w-[110px] h-[59px] font-title"
          >
            Unstake
          </button>
          <button
            onClick={showLPPoolClaimModal}
            disabled={
              !selectedAccount || userUnclaimedBalanceInMLPPool?.isZero()
            }
            className="btn-primary rounded-xl w-[244px] h-[59px] font-title"
          >
            Claim Rewards
          </button>
        </div>
      </div>

      <div className="w-[590px] bg-primary h-[320px] rounded-3xl px-12 py-6">
        <div className="font-title text-2xl leading-10 text-left">SHP Pool</div>
        <div className="my-6">
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">Maturity</span>
            <span>30 Nov 2023 (90 days)</span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">Total Staked</span>
            <span>
              {(jumboPoolData as any).totalLiquidity
                ? numeral((jumboPoolData as any).totalLiquidity)
                    .divide(10 ** AssetType.Jumbo().numberOfDecimals)
                    .format('0,0.00')
                : '--'}{' '}
              SHP
            </span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">APR</span>
            <span>{numeral((jumboPoolData as any).apr).format('0.00%')}</span>
          </div>
          <div className="flex items-center justify-between leading-5 mb-2">
            <span className="text-[rgba(43,48,53,0.8)]">My Staked</span>
            <span>
              {userStakedBalanceInJumboPool === null
                ? '--'
                : numeral(userStakedBalanceInJumboPool?.toString(2)).format(
                    '0,0.00'
                  )}{' '}
              SHP
            </span>
          </div>
          <div className="flex items-center justify-between leading-5">
            <span className="text-[rgba(43,48,53,0.8)]">
              My Unclaimed Rewards
            </span>
            <span>{displayAmount(userUnclaimedBalanceInJumboPool)} SHP</span>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={_showStakeModal}
            className="btn-tertiary rounded-xl w-[110px] h-[59px] font-title"
            disabled={!selectedAccount}
          >
            Stake
          </button>
          <button
            onClick={_showUnStakeModal}
            className="btn-tertiary rounded-xl w-[110px] h-[59px] font-title"
            disabled={
              !selectedAccount || userStakedBalanceInJumboPool?.isZero()
            }
          >
            Unstake
          </button>
          <button
            onClick={showJumboPoolClaimModal}
            className="btn-primary rounded-xl w-[244px] h-[59px] font-title"
            disabled={
              !selectedAccount || userUnclaimedBalanceInJumboPool?.isZero()
            }
          >
            Claim Rewards
          </button>
        </div>
      </div>

      <ModalWrapper>
        <JumboPoolModal isStake={isStake} hideModal={hideModal} />
      </ModalWrapper>

      {/* just a copy of JumboPoolModal, which is easier to maintain, like add/remove pool */}
      <MLPPoolModalWrapper>
        <MLPPoolModal isStake={isStake} hideModal={hideMLPPoolModal} />
      </MLPPoolModalWrapper>

      <ClaimModalWrapper>
        <ClaimBonusRewardsModal
          hideModal={hideClaimModal}
          poolId={poolId}
          rewardAmount={
            poolId === POOLS_IDS.JUMBO
              ? userUnclaimedBalanceInJumboPool
              : userUnclaimedBalanceInMLPPool
          }
        />
      </ClaimModalWrapper>
    </div>
  );
};

export default Staking;
