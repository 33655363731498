import { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';
import Balance from 'classes/Balance';
import Icon from 'components/Icon';
import { useSubstrate } from 'contexts/SubstrateContext';
import { useWallet } from 'contexts/WalletContext';
import TxResToast from 'components/TxResToast';
import { POOLS_IDS } from 'constants/FarmingPoolConstants';
import type { Signer } from '@polkadot/api/types';

const ClaimBonusRewardsModal = ({
  hideModal,
  poolId,
  rewardAmount
}: {
  hideModal: () => void;
  poolId: number;
  rewardAmount?: Balance | null;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [transferErrMsg, setTransferErrMsg] = useState('');
  const { api, apiState } = useSubstrate();
  const { selectedAccount } = useWallet();

  const handleTxRes = (tx: any) => {
    const status = tx.status;
    const dispatchError = tx.dispatchError;
    console.log('Transaction status:', status.type);
    if (status.isInBlock) {
      if (dispatchError) {
        if (dispatchError.isModule) {
          const decoded = api?.registry.findMetaError(
            dispatchError.asModule
          ) as any;
          const errorMsg = `${decoded.section}.${decoded.name}`;
          setTransferErrMsg(errorMsg);
          toast(
            <TxResToast
              isSuccess={false}
              content={
                <div>
                  Claim failed.
                  <div>{errorMsg}</div>
                </div>
              }
            />
          );
        } else {
          setTransferErrMsg(dispatchError.toString());
          toast(
            <TxResToast
              isSuccess={false}
              content={
                <div>
                  Claim failed.
                  <div>{dispatchError.toString()}</div>
                </div>
              }
            />
          );
        }
      } else {
        setIsSuccess(true);
        toast(<TxResToast isSuccess content="Claim succeeded." />);
      }
      setSubmitting(false);
    }
  };

  const handleClaim = async () => {
    if (isSuccess) {
      hideModal();
      return;
    }

    if (!api || apiState !== 'READY' || !selectedAccount) {
      return;
    }
    setSubmitting(true);
    transferErrMsg && setTransferErrMsg('');

    try {
      await api.tx.farming
        .claim(poolId)
        .signAndSend(
          selectedAccount.address,
          { signer: selectedAccount.signer as Signer, nonce: -1 },
          handleTxRes
        );
    } catch (e: any) {
      setTransferErrMsg(e.message);
      setSubmitting(false);
    }
  };

  const getClaimText = () => {
    let text = '';
    if (poolId === POOLS_IDS.JUMBO) {
      text = `${rewardAmount?.toString()} SHP from the SHP pool will be claimed as rewards and go to your wallet.`;
    } else if (poolId === POOLS_IDS.MANTA_JUMBO_LP) {
      text = `${rewardAmount?.toString()} SHP from the SHP/MANTA pool will be claimed as rewards and go to your wallet.`;
    } else {
      text = `By depositing MANTA, you've earned ${rewardAmount?.toString()} SHP as bonus rewards. Once claimed, these will be transferred directly to your wallet.`;
    }
    return text;
  };

  return (
    <div className="w-[509px]">
      <h1 className="text-2xl text-left leading-10 text-secondary font-title">
        {isSuccess ? 'Claim Successfully Finalized!' : 'Confirmation'}
      </h1>

      <div className="font-content text-base text-left mt-6">
        {isSuccess
          ? 'Your rewards are now available in your wallet!'
          : getClaimText()}
      </div>

      <div className="font-content mt-6">
        <button
          onClick={handleClaim}
          disabled={submitting}
          className="btn-primary font-title w-full rounded-xl h-[66px] flex items-center justify-center gap-4 mt-4"
        >
          {isSuccess ? 'Back' : 'Yes, Claim'}
          {submitting && (
            <ProgressSpinner
              className="w-[32px] h-[32px] m-0"
              strokeWidth="4"
            />
          )}
        </button>
        {transferErrMsg && (
          <div className="text-left flex items-center text-warning gap-2 mt-[10px]">
            <Icon name="information" />
            {transferErrMsg}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimBonusRewardsModal;
