import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { UserLotteryDataContextProvider } from 'contexts/UserLotteryDataContext';
import { UserFarmingDataContextProvider } from 'contexts/UserFarmingDataContext';
import { GlobalLotteryDataContextProvider } from 'contexts/GlobalLotteryDataContext';
import { LotteryTxContextProvider } from 'contexts/LotteryTxContext';
import AppRouter from './AppRouter';
import { SubstrateContextProvider } from './contexts/SubstrateContext';
import { WalletContextProvider } from './contexts/WalletContext';
import { UsdValueContextProvider } from './contexts/UsdValueContext';
import { AxiosContextProvider } from './contexts/AxiosContext';

const client = new ApolloClient({
  uri: 'https://squid.subsquid.io/manta-dex-manta-debug-squid/v/v4/graphql',
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="min-h-screen">
        <AxiosContextProvider>
          <UsdValueContextProvider>
            <SubstrateContextProvider>
              <WalletContextProvider>
                <GlobalLotteryDataContextProvider>
                  <UserFarmingDataContextProvider>
                    <UserLotteryDataContextProvider>
                      <LotteryTxContextProvider>
                        <AppRouter />
                      </LotteryTxContextProvider>
                    </UserLotteryDataContextProvider>
                  </UserFarmingDataContextProvider>
                </GlobalLotteryDataContextProvider>
              </WalletContextProvider>
            </SubstrateContextProvider>
          </UsdValueContextProvider>
        </AxiosContextProvider>
      </div>
    </ApolloProvider>
  );
}

export default App;
