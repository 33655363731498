import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect
} from 'react';
import BN from 'bn.js';
import Balance from 'classes/Balance';
import AssetType from 'classes/AssetType';
import { TOKEN_IDS, POOLS_IDS } from 'constants/FarmingPoolConstants';
import { useSubstrate } from './SubstrateContext';
import { useWallet } from './WalletContext';
import { useGlobalLotteryData } from './GlobalLotteryDataContext';

type UserFarmingDataContextValue = {
  userNonStakedBalanceOfJumbol: Balance | null;
  userStakedBalanceInJumboPool: Balance | null;
  userUnclaimedBalanceInJumboPool: Balance | null;

  userNonStakedBalanceOfLP: Balance | null;
  userStakedBalanceInMLPPool: Balance | null;
  userUnclaimedBalanceInMLPPool: Balance | null;
};

export type PendingWithdrawal = {
  balance: Balance;
  blockNumber: number;
};

const UserFarmingDataContext =
  createContext<UserFarmingDataContextValue | null>(null);

const UserFarmingDataContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const { api, apiState } = useSubstrate();
  const { selectedAccount } = useWallet();
  const { currentBlockNumber } = useGlobalLotteryData();

  const [userNonStakedBalanceOfJumbol, setUserNonStakedBalanceOfJumbol] =
    useState<Balance | null>(null);
  const [userStakedBalanceInJumboPool, setUserStakedBalanceInJumboPool] =
    useState<Balance | null>(null);
  const [userUnclaimedBalanceInJumboPool, setUserUnclaimedBalanceInJumboPool] =
    useState<Balance | null>(null);

  const [userNonStakedBalanceOfLP, setUserNonStakedBalanceOfLP] =
    useState<Balance | null>(null);
  const [userStakedBalanceInMLPPool, setUserStakedBalanceInMLPPool] =
    useState<Balance | null>(null);
  const [userUnclaimedBalanceInMLPPool, setUserUnclaimedBalanceInMLPPool] =
    useState<Balance | null>(null);

  // user rewards in Jumbo pool
  useEffect(() => {
    const subscribeUserUnclaimedRewards = async () => {
      if (!api || apiState !== 'READY' || !selectedAccount) {
        return;
      }
      const result = await (api.rpc as any).farming.getFarmingRewards(
        selectedAccount.address,
        POOLS_IDS.JUMBO
      );
      const jsonData = result.toJSON();
      if (jsonData[0] && jsonData[0][0] === TOKEN_IDS.JUMBO) {
        setUserUnclaimedBalanceInJumboPool(
          new Balance(
            AssetType.Jumbo(),
            new BN(BigInt(jsonData[0][1]).toString())
          )
        );
      } else {
        setUserUnclaimedBalanceInJumboPool(
          new Balance(AssetType.Jumbo(), new BN(0))
        );
      }
    };
    subscribeUserUnclaimedRewards();
  }, [api, apiState, selectedAccount, currentBlockNumber]);

  // user staked balance in Jumbo pool
  useEffect(() => {
    const handleChangeUserUnclaimedRewards = (
      unclaimedRewardsOfJumboPool: any
    ) => {
      if (unclaimedRewardsOfJumboPool.isSome) {
        setUserStakedBalanceInJumboPool(
          new Balance(
            AssetType.Jumbo(),
            new BN(unclaimedRewardsOfJumboPool.value.share.toString())
          )
        );
      } else {
        setUserStakedBalanceInJumboPool(
          new Balance(AssetType.Jumbo(), new BN(0))
        );
      }
    };

    const subscribeUserUnclaimedRewards = async () => {
      if (!api || apiState !== 'READY' || !selectedAccount) {
        return;
      }
      await api.isReady;
      unsub = await api.query.farming.sharesAndWithdrawnRewards(
        POOLS_IDS.JUMBO,
        selectedAccount.address,
        handleChangeUserUnclaimedRewards
      );
    };
    let unsub: any;
    subscribeUserUnclaimedRewards();
    return () => unsub && unsub();
  }, [api, apiState, selectedAccount]);

  // user JUMBO token balance
  useEffect(() => {
    const handleChangeNonStakedBalance = async (result: any) => {
      if (result?.value?.balance) {
        const totalBalance = new Balance(
          AssetType.Jumbo(),
          new BN(result.value.balance.toString())
        );

        setUserNonStakedBalanceOfJumbol(totalBalance);
      } else {
        setUserNonStakedBalanceOfJumbol(
          new Balance(AssetType.Jumbo(), new BN(0))
        );
      }
    };

    const subscribeNonStakedBalanceChanges = async () => {
      if (!api || !selectedAccount || apiState !== 'READY') {
        return;
      }
      await api.isReady;
      unsub = await api.query.assets.account(
        TOKEN_IDS.JUMBO,
        selectedAccount.address,
        handleChangeNonStakedBalance
      );
    };
    let unsub: any;
    subscribeNonStakedBalanceChanges();
    return () => unsub && unsub();
  }, [api, apiState, selectedAccount]);

  // user rewards in MLP pool
  useEffect(() => {
    const subscribeUserUnclaimedRewards = async () => {
      if (!api || apiState !== 'READY' || !selectedAccount) {
        return;
      }
      const result = await (api.rpc as any).farming.getFarmingRewards(
        selectedAccount.address,
        POOLS_IDS.MANTA_JUMBO_LP
      );
      const jsonData = result.toJSON();
      if (jsonData[0] && jsonData[0][0] === TOKEN_IDS.JUMBO) {
        setUserUnclaimedBalanceInMLPPool(
          new Balance(
            AssetType.Jumbo(),
            new BN(BigInt(jsonData[0][1]).toString())
          )
        );
      } else {
        setUserUnclaimedBalanceInMLPPool(
          new Balance(AssetType.MLP(), new BN(0))
        );
      }
    };
    subscribeUserUnclaimedRewards();
  }, [api, apiState, selectedAccount, currentBlockNumber]);

  // user staked balance in MLP pool
  useEffect(() => {
    const handleChangeUserUnclaimedRewards = (
      unclaimedRewardsOfMLPPool: any
    ) => {
      if (unclaimedRewardsOfMLPPool.isSome) {
        setUserStakedBalanceInMLPPool(
          new Balance(
            AssetType.MLP(),
            new BN(unclaimedRewardsOfMLPPool.value.share.toString())
          )
        );
      } else {
        setUserStakedBalanceInMLPPool(new Balance(AssetType.MLP(), new BN(0)));
      }
    };

    const subscribeUserUnclaimedRewards = async () => {
      if (!api || apiState !== 'READY' || !selectedAccount) {
        return;
      }
      await api.isReady;
      unsub = await api.query.farming.sharesAndWithdrawnRewards(
        POOLS_IDS.MANTA_JUMBO_LP,
        selectedAccount.address,
        handleChangeUserUnclaimedRewards
      );
    };
    let unsub: any;
    subscribeUserUnclaimedRewards();
    return () => unsub && unsub();
  }, [api, apiState, selectedAccount]);

  // user MANTA/JUMBO LP token balance
  useEffect(() => {
    const handleChangeNonStakedBalance = async (result: any) => {
      if (result?.value?.balance) {
        const totalBalance = new Balance(
          AssetType.MLP(),
          new BN(result.value.balance.toString())
        );
        setUserNonStakedBalanceOfLP(totalBalance);
      } else {
        setUserNonStakedBalanceOfLP(new Balance(AssetType.MLP(), new BN(0)));
      }
    };

    const subscribeNonStakedBalanceChanges = async () => {
      if (!api || !selectedAccount || apiState !== 'READY') {
        return;
      }
      await api.isReady;
      unsub = await api.query.assets.account(
        TOKEN_IDS.MANTA_JUMBO_LP,
        selectedAccount.address,
        handleChangeNonStakedBalance
      );
    };
    let unsub: any;
    subscribeNonStakedBalanceChanges();
    return () => unsub && unsub();
  }, [api, apiState, selectedAccount]);

  const state = useMemo(
    () => ({
      userNonStakedBalanceOfJumbol,
      userStakedBalanceInJumboPool,
      userUnclaimedBalanceInJumboPool,

      userNonStakedBalanceOfLP,
      userStakedBalanceInMLPPool,
      userUnclaimedBalanceInMLPPool
    }),
    [
      userNonStakedBalanceOfJumbol,
      userStakedBalanceInJumboPool,
      userUnclaimedBalanceInJumboPool,

      userNonStakedBalanceOfLP,
      userStakedBalanceInMLPPool,
      userUnclaimedBalanceInMLPPool
    ]
  );

  return (
    <UserFarmingDataContext.Provider value={state}>
      {children}
    </UserFarmingDataContext.Provider>
  );
};

const useUserFarmingData = () =>
  useContext(UserFarmingDataContext) as UserFarmingDataContextValue;

export { UserFarmingDataContextProvider, useUserFarmingData };
