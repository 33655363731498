import classNames from 'classnames';
import { useGlobalLotteryData } from 'contexts/GlobalLotteryDataContext';
import Account from './Account';
import Prize from './Prize';
import Staking from './Staking';

const PrizeAndAccount = () => {
  const { selectedTab, setSelectedTab } = useGlobalLotteryData();

  const changeTabMennu = (index: number) => {
    setSelectedTab(index);
  };
  return (
    <div className="w-[1200px] mx-auto mb-[50px]">
      <div className="flex items-center justify-center gap-5 mb-6">
        <button
          onClick={() => changeTabMennu(0)}
          className={classNames(
            'w-[248px] h-[50px] rounded-[40px] font-title',
            {
              'bg-button-primary text-white': selectedTab === 0,
              'bg-button-primary/20 text-secondary/50 border border-primary/50':
                selectedTab !== 0
            }
          )}
        >
          Prize
        </button>
        <button
          onClick={() => changeTabMennu(1)}
          className={classNames(
            'w-[248px] h-[50px] rounded-[40px] font-title',
            {
              'bg-button-primary text-white': selectedTab === 1,
              'bg-button-primary/20 text-secondary/50 border border-primary/50':
                selectedTab !== 1
            }
          )}
        >
          Account
        </button>
        {/* <button
          onClick={() => changeTabMennu(2)}
          className={classNames(
            'w-[248px] h-[50px] rounded-[40px] font-title',
            {
              'bg-button-primary text-white': selectedTab === 2,
              'bg-button-primary/20 text-secondary/50 border border-primary/50':
                selectedTab !== 2
            }
          )}
        >
          Staking
        </button> */}
      </div>
      {selectedTab === 0 ? (
        <Prize />
      ) : selectedTab === 1 ? (
        <Account />
      ) : (
        <Staking />
      )}
    </div>
  );
};

export default PrizeAndAccount;
