export const TOKEN_IDS = {
  JUMBO: 37,
  MANTA_JUMBO_LP: 40,
  VMANTA: 38
};
export const POOLS_IDS = {
  JUMBO: 1,
  MANTA_JUMBO_LP: 2,
  VMANTA: 3
};

export const POOLS_API = {
  JUMBO: '/api/pool/manta-staging:2104-2-37',
  MANTA_JUMBO_LP: '/api/pool/manta-staging:2104-2-40',
  VMANTA: '/api/pool/manta-staging:2104-2-38'
};

export const ADD_POOL_URL =
  'https://manta-dex-pool-jx3d19oge-manta-fullstack.vercel.app/pool/manta-staging:2104-2-40/add';
