import { useState, useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Nullable } from 'primereact/ts-helpers';
import BN from 'bn.js';
import Decimal from 'decimal.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';
import Icon from 'components/Icon';
import AssetType from 'classes/AssetType';
import Balance from 'classes/Balance';
import { useSubstrate } from 'contexts/SubstrateContext';
import { useWallet } from 'contexts/WalletContext';
import TxResToast from 'components/TxResToast';
import { useUserFarmingData } from 'contexts/UserFarmingDataContext';
import { POOLS_IDS } from 'constants/FarmingPoolConstants';
import type { Signer } from '@polkadot/api/types';

const decimals = AssetType.MLP().numberOfDecimals;
interface InputNumberChangeEvent {
  /**
   * Browser event
   */
  originalEvent: React.SyntheticEvent;
  /**
   * New value
   */
  value: number | null;
}

const DepositSuccess = ({
  hideModal,
  isStake
}: {
  hideModal: () => void;
  isStake?: boolean;
}) => {
  return (
    <div className="font-content mt-4">
      <div className="mb-6 leading-5">
        <div>
          {isStake
            ? 'You are now eligible to receive SHP rewards!'
            : 'The process of unstaking has been completed successfully. Your SHP are now available in your wallet!'}
        </div>
      </div>
      <button
        onClick={hideModal}
        className="font-title btn-primary w-full rounded-xl h-[66px]"
      >
        Back
      </button>
    </div>
  );
};

const MLPPoolModal = ({
  hideModal,
  isStake
}: {
  hideModal: () => void;
  isStake?: boolean;
}) => {
  const [validateErrMsg, setValidateErrMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [depositSuccess, setDepositSuccess] = useState(false);
  const {
    userNonStakedBalanceOfLP: userNonStakedBalanceOfJumbol,
    userStakedBalanceInMLPPool: userStakedBalanceInJumboPool
  } = useUserFarmingData();
  const { api, apiState } = useSubstrate();
  const { selectedAccount } = useWallet();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [value, setValue] = useState<Nullable<number | null>>(null);
  const [transferErrMsg, setTransferErrMsg] = useState('');

  const validateInputValue = (inputValue: Nullable<number | null>) => {
    if (!inputValue) {
      validateErrMsg && setValidateErrMsg('');
      setIsButtonDisabled(true);
      return;
    }
    transferErrMsg && setTransferErrMsg('');

    const inputBalanceValue = Balance.fromBaseUnits(
      AssetType.MLP(),
      new Decimal(inputValue)
    );
    const existentialDeposit = new Balance(
      AssetType.MLP(),
      AssetType.MLP().existentialDeposit
    );
    const reservedBalance = existentialDeposit;
    if (
      userNonStakedBalanceOfJumbol !== null &&
      userStakedBalanceInJumboPool !== null &&
      ((isStake &&
        inputBalanceValue.gt(
          userNonStakedBalanceOfJumbol.sub(reservedBalance)
        )) ||
        (!isStake && inputBalanceValue.gt(userStakedBalanceInJumboPool)))
    ) {
      setValidateErrMsg('Insufficient Balance');
      !isButtonDisabled && setIsButtonDisabled(true);
    } else {
      validateErrMsg && setValidateErrMsg('');
      setIsButtonDisabled(false);
    }
  };

  const handleInputChange = (e: InputNumberChangeEvent) => {
    const inputValue = e.value;
    validateInputValue(inputValue);
  };

  const handleTxRes = (tx: any) => {
    const status = tx.status;
    const dispatchError = tx.dispatchError;
    console.log('Transaction status:', status.type);
    if (status.isInBlock) {
      if (dispatchError) {
        if (dispatchError.isModule) {
          const decoded = api?.registry.findMetaError(
            dispatchError.asModule
          ) as any;
          const errorMsg = `${decoded.section}.${decoded.name}`;
          setTransferErrMsg(errorMsg);
          toast(
            <TxResToast
              isSuccess={false}
              content={
                <div>
                  {isStake ? 'Stake failed.' : 'Unstake failed.'}
                  <div>{errorMsg}</div>
                </div>
              }
            />
          );
        } else {
          setTransferErrMsg(dispatchError.toString());
          toast(
            <TxResToast
              isSuccess={false}
              content={
                <div>
                  {isStake ? 'Stake failed.' : 'Unstake failed.'}
                  <div>{dispatchError.toString()}</div>
                </div>
              }
            />
          );
        }
      } else {
        setDepositSuccess(true);
        toast(
          <TxResToast
            isSuccess
            content={isStake ? 'Stake succeeded.' : 'Unstake succeeded.'}
          />
        );
      }
      setSubmitting(false);
    }
  };

  const handleClickButton = async () => {
    if (!api || apiState !== 'READY' || !selectedAccount || !value) {
      return;
    }
    setIsButtonDisabled(true);
    setSubmitting(true);
    transferErrMsg && setTransferErrMsg('');
    Decimal.set({ toExpPos: 30 });
    try {
      if (isStake) {
        await api.tx.farming
          .deposit(
            POOLS_IDS.MANTA_JUMBO_LP,
            new Decimal(value).mul(new Decimal(10).pow(decimals)).toString(),
            null
          )
          .signAndSend(
            selectedAccount.address,
            { signer: selectedAccount.signer as Signer, nonce: -1 },
            handleTxRes
          );
      } else {
        const txs = [
          api.tx.farming.withdraw(
            POOLS_IDS.MANTA_JUMBO_LP,
            new Decimal(value).mul(new Decimal(10).pow(decimals)).toString()
          ),
          api.tx.farming.withdrawClaim(POOLS_IDS.MANTA_JUMBO_LP)
        ];
        await api.tx.utility
          .batch(txs)
          .signAndSend(
            selectedAccount.address,
            { signer: selectedAccount.signer as Signer, nonce: -1 },
            handleTxRes
          );
      }
    } catch (e: any) {
      setTransferErrMsg(e.message);
      setSubmitting(false);
      setIsButtonDisabled(false);
    }
  };

  const setMaxValue = () => {
    const targetBalance = isStake
      ? userNonStakedBalanceOfJumbol
      : userStakedBalanceInJumboPool;

    if (targetBalance?.gt(new Balance(AssetType.MLP(), new BN(0)))) {
      let max = targetBalance;
      if (isStake) {
        const existentialDeposit = new Balance(
          AssetType.MLP(),
          AssetType.MLP().existentialDeposit
        );
        max = targetBalance.sub(existentialDeposit);
      }
      setValue(+max?.toString(2));
      validateInputValue(+max?.toString(2));
    } else {
      setValue(0);
      validateInputValue(0);
    }
  };

  // the balance was refreshed before `isInBlock`, here just mock a synchronous behavior
  const [
    displayUserNonStakedBalanceOfJumbol,
    setDisplayUserNonStakedBalanceOfJumbol
  ] = useState(userNonStakedBalanceOfJumbol);
  const [
    displayUserStakedBalanceInJumboPool,
    setDisplayUserStakedBalanceInJumboPool
  ] = useState(userStakedBalanceInJumboPool);
  useEffect(() => {
    if (!submitting) {
      setDisplayUserNonStakedBalanceOfJumbol(userNonStakedBalanceOfJumbol);
      setDisplayUserStakedBalanceInJumboPool(userStakedBalanceInJumboPool);
    }
  }, [userNonStakedBalanceOfJumbol, userStakedBalanceInJumboPool, submitting]);

  const getModalTitle = () => {
    let title;
    if (isStake) {
      title = 'Stake MLP';
      if (depositSuccess) {
        title = (
          <div>
            Staking Successfully <br />
            Completed!
          </div>
        );
      }
    } else {
      title = 'Unstake MLP';
      if (depositSuccess) {
        title = (
          <div>
            Unstaking Successfully <br />
            Completed!
          </div>
        );
      }
    }
    return title;
  };

  const getButtonText = () => {
    let text = '';
    if (isStake) {
      if (submitting) {
        text = 'Staking';
      } else {
        text = 'Stake';
      }
    } else {
      if (submitting) {
        text = 'Unstaking';
      } else {
        text = 'Unstake';
      }
    }
    return text;
  };

  return (
    <div className="w-[509px] text-left">
      <h1 className="text-2xl leading-10 text-secondary font-title">
        {getModalTitle()}
      </h1>
      {depositSuccess ? (
        <DepositSuccess hideModal={hideModal} isStake={isStake} />
      ) : (
        <div className="font-content mt-6">
          {isStake && (
            <div className="font-base leading-5 mb-6">
              Earn rewards by staking MLP into the SHP/MANTA Pool
            </div>
          )}
          <div className="h-[88px] bg-primary rounded-xl mb-2 flex items-center pr-6 px-[26.5px] gap-3">
            <Icon name="MLP" className="w-[36px] h-[36px]" />
            <InputNumber
              className="flex-1 h-10 pl-2 bg-primary text-xl"
              value={value}
              onValueChange={e => setValue(e.value)}
              onChange={handleInputChange}
              mode="decimal"
              placeholder="0.00"
              minFractionDigits={2}
              maxFractionDigits={2}
            />
            <button
              className="text-secondary"
              onClick={setMaxValue}
              disabled={submitting}
            >
              Max
            </button>
          </div>
          <div className="flex items-center justify-between text-sm">
            <div className="text-[#2B3036CC]">
              {isStake ? 'My Available Balance:' : 'My Staked:'}{' '}
              {isStake
                ? displayUserNonStakedBalanceOfJumbol?.toString(2)
                : displayUserStakedBalanceInJumboPool?.toString()}{' '}
              MLP
            </div>
            {validateErrMsg && (
              <div className="flex items-center text-warning gap-2">
                <Icon name="information" />
                {validateErrMsg}
              </div>
            )}
          </div>
          {isStake && (
            <div className="flex items-center justify-between text-base leading-5 mt-6 mb-4">
              <span>My Staked Balance</span>
              <span>
                {displayUserStakedBalanceInJumboPool?.toString(2)} MLP
              </span>
            </div>
          )}
          <button
            onClick={handleClickButton}
            disabled={isButtonDisabled}
            className="btn-primary font-title w-full rounded-xl h-[66px] flex items-center justify-center gap-4 mt-4"
          >
            {getButtonText()}
            {submitting && (
              <ProgressSpinner
                className="w-[32px] h-[32px] m-0"
                strokeWidth="4"
              />
            )}
          </button>
          {transferErrMsg && (
            <div className="text-left flex items-center text-warning gap-2 mt-[10px]">
              <Icon name="information" />
              {transferErrMsg}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MLPPoolModal;
